require('../scss/app.scss');


require('./waypoints.min');
require('./db_custom');
require('./owl.carousel.min');
require('./masonry.pkgd.min');
require('./colorbox-min');
require('./lightbox');
require('./jarallax');



require('@fortawesome/fontawesome-free')

require('./custom');


$(function () {


    Holder.addTheme("thumb", {
        background: "#55595c",
        foreground: "#eceeef",
        text: "Thumbnail"
    });
    $('#myCarousel').carousel({
        // Options
    });
});

$=jQuery;
jQuery('#toggle').click(function(e) {
    e.preventDefault();

    jQuery('.nav_main ul').slideToggle("slow");

    // Animation complete.

});










